<template>
  <v-container fluid class="pa-0 ma-0">
    <title>Course Page</title>
    <Loading title="Loading course details" :loading="courseSelectedLoading"/>
    <div v-if="courseSelected">
      <Course :course="courseSelected"
              :navigate-to-course="false"
      />
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Loading from "@/components/common/Loading";
import Course from "@/components/course/Course";

export default {
  name: "ViewCourse",
  metaInfo() {
    return {
      title: this.courseSelected ? `Category: ${this.courseSelected.name}` : 'Course',
      meta: [
        { description: this.courseSelected ? this.courseSelected.description : 'Course description' },
        { image: this.courseSelected ? this.courseSelected.image : 'Course image' },
      ],
    };
  },

  components: {
    Loading,
    Course,
  },
  computed: {
    ...mapState(['courseSelected', 'courseSelectedLoading']),
    ...mapGetters(['courseSelected', 'courseSelectedLoading']),
  },
  methods: {
    ...mapActions(['getCourseBySlug']),
  },
  async created() {

    await this.getCourseBySlug(this.$route.params.slug);

    if(! this.courseSelected){
      await this.$router.replace("/not-found");
    }

  },
};
</script>
