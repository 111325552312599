<template>
  <div>
    <v-card
        class="mx-auto"
        color="transparent"
        flat
    >
      <v-card-actions>
        <v-btn
            title="Share this course"
            aria-label="Share this course"
            text
            plain
            color="accent"
            class="pp-center"
            :ripple="false"
            height="50"
            @click="setReveal(true);"
        >
          <v-icon class="mr-2">
            mdi-share
          </v-icon>
          {{ this.prompt }}
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <v-card
            v-if="reveal"
            class="transition-fast-in-fast-out v-card--reveal pa-1"
            color="secondary darken-2"
            height="50px"
            flat
        >
          <div class="mx-auto text-center pt-0 mb-5">
            <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :url="sharing.url"
                :title="title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
                @click="matomoEvent('Share Course', 'Share Course Click', 'Share Course on social media');"
            >
              <v-icon
                  :color="network.color"
                  class="mx-1 pp-scale pt-0 mt-0"
                  large
              >
                mdi-{{ network.icon }}
              </v-icon>
            </ShareNetwork>

            <div class="text-center pl-md-10">
              <v-chip-group
                  multiple
                  class="pp-center"
              >
                <v-chip
                    filter
                    outlined
                    color="green"
                    @click="copyURL();"
                    class="px-10"
                >
                  Copy Link
                </v-chip>

                <v-icon
                    color="grey"
                    @click="setReveal(false)"
                    class="ml-2 pp-scale"
                >
                  mdi-close-circle-outline
                </v-icon>
              </v-chip-group>
            </div>
          </div>
        </v-card>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SocialMediaShare",

  data () {
    return {
      sharing: {
        url: `${this.getUrl()}\n`,
        description: 'LearningPlus is one of the UK\'s leading education providers, used by thousands of people, who wish to keep up in a fast paced job market. Whether you are looking to take a step up in your career or train in a whole new field, we have an extensive range of courses that can help you to achieve this.',
        hashtags: 'LearningPlus,PeoplePlus,Upskill',
      },
      networks: [
        { network: 'email', name: 'Email', icon: 'email-outline', color: 'red' },
        { network: 'facebook', name: 'Facebook', icon: 'facebook', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'linkedin', color: '#007bb5' },
        { network: 'messenger', name: 'Messenger', icon: 'facebook-messenger', color: '#0084ff' },
        { network: 'sms', name: 'SMS', icon: 'message-processing', color: 'green' },
        { network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366' },
      ],
    };
  },

  methods: {

    copyURL() {
      navigator.clipboard.writeText(this.getUrl());
    },

    getUrl() {
      return location.toString();
    },

    ...mapActions(['setReveal']),
  },
  computed: {
    ...mapGetters(['reveal']),
  },

  props: {
    title: {
      type: String,
      default: `Check this out!`,
    },

    prompt: {
      type: String,
      default: `Share this`,
    },
  },
};
</script>

<style scoped>

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

</style>
