<template>
  <v-card
      class="rounded-xl"
      flat
  >
    <v-stepper
        v-model="e6"
        vertical
        non-linear
    >
      <v-stepper-step
          :complete="e6 > 1"
          step="1"
      >
        Activate your account
      </v-stepper-step>

      <v-stepper-content step="1">
        <picture>
          <img
              src="/images/categories/email.jpg"
              alt="Open an email"
              height="150"
              width="100%"
              class="rounded-xl elevation-5 cover"
          >
        </picture>

        <v-row>
          <v-col sm="9" cols="12" >
            <p class="text-body-2 mt-sm-5 pt-5 pt-sm-0">
              Once you have purchased your course you will immediately receive a confirmation email with a link, username and password. This will give you access to your chosen course.
            </p>
          </v-col>

          <v-col class="pt-0 pt-sm-5">
            <div class="text-right">
              <v-btn
                  color="primary"
                  @click="e6 = 2"
                  rounded
                  class="my-sm-3"
                  title="Next"
                  aria-labelledby="Next step"
              >
                Next
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step
          :complete="e6 > 2"
          step="2"
      >
        Take your course on laptop, tablet or smartphone
      </v-stepper-step>

      <v-stepper-content step="2">
        <picture>
          <img
            src="/images/categories/phone.jpg"
            alt="Access LearningPlus portal from the phone"
            height="150"
            width="100%"
            class="rounded-xl elevation-5 cover"
        >
        </picture>

        <v-row>
          <v-col sm="9" cols="12" >
            <p class="text-body-2 mt-5">
              Stream and watch your content on the the plane, train or wherever you learn best.
            </p>
          </v-col>

          <v-col>
            <div class="text-right">
              <v-btn
                  color="primary"
                  @click="e6 = 3"
                  rounded
                  class="my-sm-3"
                  title="Next"
                  aria-labelledby="Next step"
              >
                Next
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step
          :complete="e6 > 3"
          step="3"
      >
        Complete both the course and the Final Assessment
      </v-stepper-step>

      <v-stepper-content step="3">
        <picture>
          <img
            src="/images/categories/exam.jpg"
            alt="LearningPlus online exam"
            height="150"
            width="100%"
            class="rounded-xl elevation-5 cover"
        >
        </picture>

        <v-row>
          <v-col sm="9" cols="12" >
            <p class="text-body-2 mt-5">
              Complete your online assessment (if applicable) summarising your understanding of your course.
            </p>
          </v-col>

          <v-col>
            <div class="text-right">
              <v-btn
                  color="primary"
                  @click="e6 = 4"
                  rounded
                  class="my-sm-3"
                  title="Next"
                  aria-labelledby="Next step"
              >
                Next
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step step="4">
        Download and print off your certificate
      </v-stepper-step>
      <v-stepper-content step="4">
        <picture>
          <img
              src="/images/categories/certificate.jpg"
              alt="Print a LearningPlus Certificate"
              height="150"
              width="100%"
              class="rounded-xl elevation-5 cover"
          />
        </picture>
        <v-row>
          <v-col sm="9" cols="12" >
            <p class="text-body-2 mt-5">
              Once completed and passed your certificate will be emailed to you, print it off and include in your resume for job interviews.
            </p>
          </v-col>

          <v-col>
            <div class="text-right">
              <v-btn
                  color="primary"
                  @click="e6 = 1"
                  rounded
                  class="my-sm-3"
                  title="Next"
                  aria-labelledby="Next step"
              >
                Finish
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<script>
export default {
  name: "CourseJourney",

  data () {
    return {
      e6: 1,
    };
  },
};
</script>
