<template>
  <div
      v-if="isPopular"
      class="black--text pp-ribbon elevation-15"
  >
    <v-icon class="black--text mr-2">
      mdi-trending-up
    </v-icon>

    In-demand
  </div>
</template>

<script>
export default {
  name: "InDemandRibbon",
  props: {
    isPopular: Boolean,
  },
};
</script>

<style scoped>
.pp-ribbon {
  z-index: 20;
  position: absolute;
  top: 20px;
  left: -5px;
  font-weight: bold;
  padding: 5px 20px;
  border-radius: 5px 20px 20px 5px;
  background-color: #C1D401;
}
</style>