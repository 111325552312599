<template>
  <v-list outlined class="rounded-xl py-4">
    <v-list-group>
      <template v-slot:activator>
        <v-list-item-icon class="mr-3">
          <v-icon>
            mdi-file-certificate-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Nationally recognised Certificate of Completion
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <div class="text-body-1 px-4 ml-5">
            <p>
              Our qualifications are Regulated Qualifications issued by the leading Awarding Organisations that are recognised throughout the UK.
            </p>

            <p>
              The certificate successful learners receive on completion of a Regulated Qualification will contain the logo of the Awarding Organisation, and the relevant UK Regulator (Ofqual) along with the full qualification title and qualification number.
            </p>
          </div>

          <v-divider class="mt-5" />
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group>
      <template v-slot:activator>
        <v-list-item-icon class="mr-3">
          <v-icon>
            mdi-cellphone-play
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Accessible on all devices, including mobile and TV
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item >
        <v-list-item-content>
          <div class="text-body-1 px-5 ml-3">
            Take classes on the go through our portal. Stream to watch on the train, on the bus, or wherever you learn best.
          </div>
          <v-divider class="mt-5" />
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group>
      <template v-slot:activator>
        <v-list-item-icon class="mr-3">
          <v-icon>
            mdi-star-circle-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Key features:
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item >
        <v-list-item-content>
          <div class="text-body-1 px-5 ml-3">
            <ul>
              <li>Module objectives</li>
              <li>High quality colour images</li>
              <li>In-course quick reviews</li>
              <li>End of course test and certificate</li>
            </ul>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "CourseDescription",
};
</script>
