<template>
  <v-col cols="5">
    <div class="pp-absolute pr-16">
      <v-card
          class="rounded-xl secondary darken-2 elevation-14"
          width="500"
      >
        <div>
          <InDemandRibbon :isPopular="course.popular" />
          <picture v-if="course.image">
            <img
                :src="course.image.url"
                :alt="course.image.alt"
                class="rounded-xl elevation-5 cover"
                height="400"
                width="100%"
            />
          </picture>

          <picture v-else>
            <img
                 src="/images/categories/pp-default.jpg"
                 alt="LearningPlus Course"
                 class="rounded-xl elevation-5 cover"
                 height="400"
                 width="100%"
            />
          </picture>
        </div>

        <div>
          <v-row class="pa-10">
            <div class="text-h6 accent--text">
              {{ course.name }}
            </div>
            <v-spacer></v-spacer>
            <v-card-title
                v-if="course.priceInUnits"
                class="text-h3 white--text px-0 pt-0 font-weight-bold"
            >
              &pound;{{ unitsToDecimal(course.priceInUnits) }}
              <span class="text-caption white--text ml-2">
                inc VAT
              </span>
            </v-card-title>
          </v-row>

          <div class="px-10">
            <BuyNow :item="course" />
          </div>

          <SocialMediaShare
              prompt="Share this Course"
              :title="`View ${this.course.name} Course by LearningPlus`"
          />
        </div>

        <div class="pa-0 ma-0">
          <v-divider dark class="mt-10" />

          <div class="accent--text text-h5 font-weight-bold pt-md-15 text-center">
            Upskilling your staff or training a team?
          </div>

          <div class="text-center pb-10">
            <div class="pp-hr-2 pp-center"></div>
            <ContactUsButton />
            <div class="pp-hr-2 pp-center mt-5 "></div>
          </div>
        </div>
      </v-card>
    </div>
  </v-col>
</template>

<script>
import SocialMediaShare from "@/components/socialMedia/SocialMediaShare";
import BuyNow from "@/components/basket/BuyNow";
import {mapActions} from "vuex";
import {unitsToDecimal} from "@/utility";
import InDemandRibbon from "@/components/common/InDemandRibbon";
import ContactUsButton from "@/components/buttons/ContactUsButton";

export default {
  name: "CourseMarketing",
  components: {ContactUsButton, InDemandRibbon, BuyNow, SocialMediaShare},
  props: {
    course: {
      type: Object,
    },
  },
  methods: {
    ...mapActions(['setCourseSelected']),
    unitsToDecimal: unitsToDecimal,
  },
};
</script>
