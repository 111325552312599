<template>
  <v-btn rounded
         class="mt-4 primary pp-scale-1 elevation-5"
         x-large
         block
         dark
         title="Buy Course Now"
         aria-labelledby="Buy Course Now"
         @click="add"
  >
    Buy Now
    <v-icon class="ml-3">
      mdi-cart
    </v-icon>
  </v-btn>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "BuyNow",
  mixins: [matomoEcommerce],
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['basketValue']),
  },
  methods: {
    ...mapActions(['addToBasketItem', 'showBasket']),
    add: function () {
      this.matomoEvent('Basket', 'Basket Click', 'Add to basket');
      this.item.amountToAddToBasket = 1;
      this.addToBasketItem(this.item);
      this.matomoAddBasket(this.item, this.basketValue);
      this.showBasket();
    },
  },
};
</script>
