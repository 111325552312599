<template>
  <div>
    <v-container
        class="primary pt-13"
        fluid
    >
      <v-container class="px-xl-16">
        <v-row class="mt-lg-10">
          <v-col lg="7" md="6" sm="12">
            <GoBackButton class="pt-5" />

            <div class="hidden-md-and-up">
              <InDemandRibbon :isPopular="course.popular" class="mt-20" />
              <picture v-if="course.image">
                <img
                    :src="course.image.url"
                    :alt="course.image.alt"
                    class="rounded-xl elevation-5 mt-3 cover"
                    :height="height"
                    width="100%"
                />
              </picture>

              <picture v-else>
                <img
                    src="/images/categories/pp-default.jpg"
                    alt="LearningPlus Course"
                    class="rounded-xl elevation-5 mt-3 cover"
                    :height="height"
                    width="100%"
                />
              </picture>
            </div>

            <h1 class="pp-page-title pp-left-line text-h5 text-md-h3">
              {{ course.name }}
            </h1>

            <v-row class="mt-3">
              <v-card v-if="course.level" color="transparent" flat>
                <v-col class="pl-0 pb-0">
                  <v-list-item>
                    <v-list-item-avatar color="secondary darken-2">
                      <v-icon color="accent">
                        mdi-numeric
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle class="accent--text">
                        Level
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="font-weight-bold text-h71 accent--text">
                        {{ course.level.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-card>

              <v-card color="transparent" v-if="course.certificationBody" flat>
                <v-col class="pl-0 pb-0 ml-0">
                  <v-list-item>
                    <v-list-item-avatar color="secondary darken-2">
                      <v-icon class="accent--text">
                        mdi-seal-variant
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle class=" accent--text">
                        Awarding Body
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="font-weight-bold text-h71 accent--text">
                        {{ course.certificationBody.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-card>

              <v-card color="transparent" v-if="course.priceInUnits" flat>
                <v-col class="pl-0 pb-0 ml-0">
                  <v-list-item>
                    <v-list-item-avatar color="secondary darken-2">
                      <v-icon class="accent--text">
                        mdi-currency-gbp
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle class="accent--text">
                        Price
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="font-weight-bold text-h71 accent--text">
                        &pound;{{ unitsToDecimal(course.priceInUnits) }}
                        <span class="text-caption">
                          inc VAT
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-card>
            </v-row>

            <v-row class="pt-4">
              <div class="py-0 font-weight-bold pt-1 accent--text">
                Categories:
              </div>

              <div v-for="item in course.categories"
                   :key="item.id"
              >

                <v-chip class="px-3 ma-1 white--text pp-scale-1"
                        color="white"
                        outlined
                        small
                        :to="getLink(item)"
                        @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'Category Page');"
                        v-on:click="setContent(item)"
                >
                  {{ item.name }}
                </v-chip>
              </div>
            </v-row>
          </v-col>

          <div class="hidden-sm-and-down">
            <CourseMarketing :course="course" />
          </div>

        </v-row>
      </v-container>
    </v-container>

    <div class="secondary darken-2 pb-12 hidden-md-and-up">
      <div class="hidden-md-and-up px-10 pt-5">
        <BuyNow :item="course" />
      </div>

      <SocialMediaShare
          prompt="Share this Course"
          :title="`View ${this.course.name} Course by LearningPlus`" />
    </div>

    <v-container>
      <v-row>
        <v-col md="6" sm="12" class="grey--text text--darken-3 pt-5">
          <v-card-title class="px-0 font-weight-bold">
            About this course
          </v-card-title>

          <div class="pp-hr mt-0"></div>

          <h2 class="text-h7 font-weight-regular mb-10 pre-line">
            {{ course.description }}
          </h2>

          <v-card-title class="px-0 font-weight-bold">
            This course includes
          </v-card-title>

          <div class="pp-hr mt-0"></div>

          <CourseDescription />

          <v-card-title class="px-0 font-weight-bold mt-4">
            Your journey
          </v-card-title>

          <div class="pp-hr mt-0" />

          <CourseJourney />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import BuyNow from "@/components/basket/BuyNow";
import CourseDescription from "@/components/course/CourseDescription";
import {unitsToDecimal} from "@/utility";
import CourseJourney from "@/components/course/CourseJourney";
import CourseMarketing from "@/components/course/CourseMarketing";
import SocialMediaShare from "@/components/socialMedia/SocialMediaShare";
import GoBackButton from "@/components/buttons/GoBackButton";
import InDemandRibbon from "@/components/common/InDemandRibbon";

export default {
  name: "Course",
  components: {InDemandRibbon, GoBackButton, SocialMediaShare, CourseMarketing, CourseJourney, BuyNow, CourseDescription},
  props: {
    course: {
      type: Object,
    },
  },
  methods: {
    ...mapActions(['setCourseSelected', 'setCategorySelected']),
    unitsToDecimal: unitsToDecimal,
    getLink(item){
      return { name: 'Category', params:{ slug: item.slug }};
    },
    setContent(item){
      this.setCategorySelected({category: item, page: 1});
    },
  },
  computed: {
    height () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 180;
      }
      return 300;
    },
  },
};
</script>

<style scoped>
.v-card--link:active {
  background-color: white !important;
}
.mt-20 {
  margin-top: 120px;
}

.pp-hr {
  z-index: 10;
  width: 120px;
}
</style>
